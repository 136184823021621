<template>
  <div class="digitalContainer">
    <div class="containers">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style lang="scss" scoped>
.digitalContainer {
  height: 100%;
}
.containers {
  width: 100%;
  height: 100%;
  margin-left: 0;
  overflow-y: auto;
  background: rgb(237, 241, 242);
  min-height: 100%;
  box-sizing: border-box;
}
</style>
