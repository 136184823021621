import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store";
import "./routeGuard";
import * as echarts from "echarts";
//  引入echarts
// import "echarts/lib/chart/bar";
// import "echarts/lib/chart/line";
// import "echarts/lib/chart/pie";
// import "echarts/theme/macarons";
// import "echarts/lib/component/tooltip";
import ECharts from "vue-echarts";

import {post, get, del, put} from './request/http';
import md5 from 'js-md5';
import CryptoJS from "crypto-js";

import installIcon from './utils/icons';
// 注册组件后即可使用
const app = createApp(App);
app.config.globalProperties.$echarts = echarts; //这种方法是直接绑定在vue实例上，所以在项目中任何页面，直接 this.$echarts 即可
app.config.globalProperties.$post = post;
app.config.globalProperties.$get = get;
app.config.globalProperties.$del = del;
app.config.globalProperties.$put = put;
app.config.globalProperties.$md5 = md5;
app.config.globalProperties.$CryptoJS = CryptoJS;
app.component("v-chart", ECharts);
app.use(ElementPlus, {
    locale: zhCn
});
app.use(router);
app.use(store);
app.use(installIcon);
app.mount("#app");
