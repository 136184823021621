import { reactive } from 'vue';

import { Avatar, Document, Operation, Postcard, User, HomeFilled, DocumentCopy } from '@element-plus/icons-vue';

const dictIcon = reactive({
    'Avatar': Avatar,
    'Document': Document,
    'Operation': Operation,
    'Postcard': Postcard,
    'User': User,
    'HomeFilled': HomeFilled,
    'DocumentCopy': DocumentCopy
})

const installIcon = (app) => {
    app.config.globalProperties.$icon = dictIcon;
}

export default installIcon;