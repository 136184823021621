import router from './router';

const whiteList = ['/login'];

router.beforeEach((to, from, next) => {
    const hasToken = localStorage.getItem('user_token')
    if (hasToken) {
        //有token的情况下，说明已经登录
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            next();
        }
    } else {
        if (whiteList.indexOf(to.path) > -1) {
            next();
        } else {
            next({name: 'login'})
        }
    }
})