import pageRoutes from '../router/pageRoutes'

const menu = {
    state: {
        routes: pageRoutes
    },
    mutations: {
        SET_MENU(state, payload) {
            state.routes = payload
        }
    }
}

export default menu