import Layout from "../pages/layout/index.vue";

import contentLayout from "../pages/layout/contentLayout.vue";

const pageRoutes = [
  {
    path: "/homePage",
    component: Layout,
    redirect: "/homePage/homePage",
    meta: {
      title: "首页",
      icon: "HomeFilled",
      path: "/homePage",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "homePage",
        name: "homePage",
        component: () => import("../pages/homePage/index.vue"),
      },
    ],
  },
  {
    path: "/projectManagement",
    component: Layout,
    redirect: "/projectManagement/projectManagement",
    meta: {
      title: "项目组合管理",
      icon: "Document",
      path: "/projectManagement",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "projectManagement",
        name: "projectManagement",
        component: () => import("../pages/projectManagement/index.vue"),
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    redirect: "/content/content",
    meta: {
      title: "数字工场运营",
      icon: "DocumentCopy",
      path: "/content",
      isShow: true,
      sole: false,
    },
    children: [
      // {
      //   path: "/content/content",
      //   name: "content",
      //   component: () =>
      //     import(
      //       "../pages/contentManagement/interpretationStrongOperators/index.vue"
      //     ),
      //   meta: {
      //     title: "强算子解读",
      //     isShow: true,
      //   },
      // },
      // {
      //   path: "/content/strongOperator",
      //   name: "strongOperator",
      //   component: () =>
      //     import("../pages/contentManagement/strongOperator/index.vue"),
      //   meta: {
      //     title: "强算子管理",
      //     isShow: true,
      //   },
      // },
      // {
      //   path: "/content/digitalFrameStockSelection",
      //   name: "digitalFrameStockSelection",
      //   component: () =>
      //     import(
      //       "../pages/contentManagement/digitalFrameStockSelection/index.vue"
      //     ),
      //   meta: {
      //     title: "数字帧选股",
      //     isShow: true,
      //   },
      // },
      // {
      //   path: "/content/subjectDataManagement",
      //   name: "subjectDataManagement",
      //   component: () =>
      //     import("../pages/contentManagement/subjectDataManagement/index.vue"),
      //   meta: {
      //     title: "主题数据管理",
      //     isShow: true,
      //   },
      // },
      // {
      //   path: "/content/originPolicyManagement",
      //   name: "originPolicyManagement",
      //   component: () =>
      //     import("../pages/contentManagement/originPolicyManagement/index.vue"),
      //   meta: {
      //     title: "原点策略管理",
      //     isShow: true,
      //   },
      // },
      // {
      //   path: "/content/personalityRecommendationStrategy",
      //   name: "personalityRecommendationStrategy",
      //   component: () =>
      //     import(
      //       "../pages/contentManagement/personalityRecommendationStrategy/index.vue"
      //     ),
      //   meta: {
      //     title: "个性推荐策略",
      //   },
      // },
      // {
      //   path: "/content/diskbeforeTheBriefing",
      //   name: "diskbeforeTheBriefing",
      //   component: () =>
      //     import("../pages/contentManagement/diskbeforeTheBriefing/index.vue"),
      //   meta: {
      //     title: "盘前简报",
      //   },
      // },
      // {
      //   path: "/content/abnormalIntradayMovement",
      //   name: "abnormalIntradayMovement",
      //   component: () =>
      //     import(
      //       "../pages/contentManagement/abnormalIntradayMovement/index.vue"
      //     ),
      //   meta: {
      //     title: "盘中异动",
      //   },
      // },
      // 识别区域
      {
        path: "/content/identifyAreas",
        name: "identifyAreas",
        component: contentLayout,
        meta: {
          title: "识别区域",
          icon: "Operation",
          path: "/content/identifyAreas",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "强算子解读",
              isShow: true,
            },
            path: "/content/identifyAreas/interpretationStrongOperators",
            name: "interpretationStrongOperators",
            component: () =>
              import(
                "../pages/contentManagement/interpretationStrongOperators/index.vue"
              ),
          },
          {
            meta: {
              title: "强算子管理",
              isShow: true,
            },
            path: "/content/identifyAreas/strongOperator",
            name: "strongOperator",
            component: () =>
              import("../pages/contentManagement/strongOperator/index.vue"),
          },
          {
            meta: {
              title: "盘前简报",
              isShow: true,
            },
            path: "/content/identifyAreas/diskbeforeTheBriefing",
            name: "diskbeforeTheBriefing",
            component: () =>
              import(
                "../pages/contentManagement/diskbeforeTheBriefing/index.vue"
              ),
          },
        ],
      },
      // 入选区域
      {
        path: "/content/selectedAreas",
        name: "selectedAreas",
        component: contentLayout,
        meta: {
          title: "入选区域",
          icon: "Operation",
          path: "/content/selectedAreas",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "个性推荐策略",
              isShow: true,
            },
            path: "/content/selectedAreas/personalityRecommendationStrategy",
            name: "personalityRecommendationStrategy",
            component: () =>
              import(
                "../pages/contentManagement/personalityRecommendationStrategy/index.vue"
              ),
          },
          {
            meta: {
              title: "每周共识策略",
              isShow: true,
            },
            path: "/content/selectedAreas/weeklyConsensusStrategy",
            name: "weeklyConsensusStrategy",
            component: () =>
              import(
                "../pages/contentManagement/weeklyConsensusStrategy/index.vue"
              ),
          },
          {
            meta: {
              title: "每日入选策略",
              isShow: true,
            },
            path: "/content/selectedAreas/dailySelectionStrategy",
            name: "dailySelectionStrategy",
            component: () =>
              import(
                "../pages/contentManagement/dailySelectionStrategy/index.vue"
              ),
          },
        ],
      },
      // 品控区域
      {
        path: "/content/qualityControlArea",
        name: "qualityControlArea",
        component: contentLayout,
        meta: {
          title: "品控区域",
          icon: "Operation",
          path: "/content/qualityControlArea",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "主题数据管理",
              isShow: true,
            },
            path: "/content/qualityControlArea/subjectDataManagement",
            name: "subjectDataManagement",
            component: () =>
              import(
                "../pages/contentManagement/subjectDataManagement/index.vue"
              ),
          },
          {
            meta: {
              title: "原点策略管理",
              isShow: true,
            },
            path: "/content/qualityControlArea/originPolicyManagement",
            name: "originPolicyManagement",
            component: () =>
              import(
                "../pages/contentManagement/originPolicyManagement/index.vue"
              ),
          },
          {
            meta: {
              title: "敏感特征品控",
              isShow: true,
            },
            path: "/content/qualityControlArea/qualityControl",
            name: "qualityControl",
            component: () =>
              import("../pages/contentManagement/qualityControl/index.vue"),
          },
        ],
      },
      // 组合编辑区域
      {
        path: "/content/combinationEditingArea",
        name: "combinationEditingArea",
        component: contentLayout,
        meta: {
          title: "组合编辑区域",
          icon: "Operation",
          path: "/content/combinationEditingArea",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "数字帧选股",
              isShow: true,
            },
            path: "/content/combinationEditingArea/digitalFrameStockSelection",
            name: "digitalFrameStockSelection",
            component: () =>
              import(
                "../pages/contentManagement/digitalFrameStockSelection/index.vue"
              ),
          },
        ],
      },
      // 质控区域
      {
        path: "/content/zhiControlArea",
        name: "zhiControlArea",
        component: contentLayout,
        meta: {
          title: "质控区域",
          icon: "Operation",
          path: "/content/zhiControlArea",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "盘中异动",
              isShow: true,
            },
            path: "/content/zhiControlArea/abnormalIntradayMovement",
            name: "abnormalIntradayMovement",
            component: () =>
              import(
                "../pages/contentManagement/abnormalIntradayMovement/index.vue"
              ),
          },
        ],
      },
      // 组合建立区域 combinationEstablishmentArea
      {
        path: "/content/combinationEstablishmentArea",
        name: "combinationEstablishmentArea",
        component: contentLayout,
        meta: {
          title: "组合建立区域",
          icon: "Operation",
          path: "/content/combinationEstablishmentArea",
          isShow: true,
          sole: true,
        },
        children: [
          {
            meta: {
              title: "主线池配置",
              isShow: true,
            },
            path: "/content/combinationEstablishmentArea/mainlinePoolConfiguration",
            name: "mainlinePoolConfiguration",
            component: () =>
              import(
                "../pages/contentManagement/combinationEstablishmentArea/mainlinePoolConfiguration/index.vue"
              ),
          },
          {
            meta: {
              title: "自定义组合推荐",
              isShow: true,
            },
            path: "/content/combinationEstablishmentArea/customCombinationRecommendation",
            name: "customCombinationRecommendation",
            component: () =>
              import(
                "../pages/contentManagement/combinationEstablishmentArea/customCombinationRecommendation/index.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/warehouse",
    component: Layout,
    redirect: "/warehouse/management",
    meta: {
      title: "操作仓管理",
      icon: "Operation",
      path: "/warehouse",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "management",
        name: "management",
        component: () => import("../pages/warehouse/index.vue"),
      },
    ],
  },
  // {
  //   path: "/commandWarehouse",
  //   component: Layout,
  //   redirect: "/commandWarehouse/commandManagement",
  //   meta: {
  //     title: "指令仓管理",
  //     icon: "Operation",
  //     path: "/commandWarehouse",
  //     isShow: true,
  //     sole: true,
  //   },
  //   children: [
  //     {
  //       path: "commandManagement",
  //       name: "commandManagement",
  //       component: () => import("../pages/commandWarehouse/index.vue"),
  //     },
  //   ],
  // },
  {
    path: "/userManagement",
    component: Layout,
    redirect: "/userManagement/userManagement",
    meta: {
      title: "用户管理",
      icon: "Avatar",
      path: "/userManagement",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "userManagement",
        name: "userManagement",
        component: () => import("../pages/userManagement/index.vue"),
      },
    ],
  },
  {
    path: "/deptManagement",
    component: Layout,
    redirect: "/deptManagement/deptManagement",
    meta: {
      title: "部门管理",
      icon: "Document",
      path: "/deptManagement",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "deptManagement",
        name: "deptManagement",
        component: () => import("../pages/deptManagement/index.vue"),
      },
    ],
  },
  {
    path: "/rightManagement",
    component: Layout,
    redirect: "/rightManagement/rightManagement",
    meta: {
      title: "权限管理",
      icon: "User",
      path: "/rightManagement",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "rightManagement",
        name: "rightManagement",
        component: () => import("../pages/rightManagement/index.vue"),
      },
    ],
  },
  {
    path: "/roleManagement",
    component: Layout,
    redirect: "/roleManagement/roleManagement",
    meta: {
      title: "角色管理",
      icon: "Postcard",
      path: "/roleManagement",
      isShow: true,
      sole: true,
    },
    children: [
      {
        path: "roleManagement",
        name: "roleManagement",
        component: () => import("../pages/roleManagement/index.vue"),
      },
    ],
  },
  {
    path: "/sysManagement",
    component: Layout,
    redirect: "/sysManagement/sysUserManagement",
    meta: {
      title: "系统管理",
      icon: "Postcard",
      path: "/sysManagement",
      isShow: true,
      sole: false,
    },
    children: [
      {
        path: "/sysManagement/sysUserManagement",
        name: "sysUserManagement",
        component: () => import("../pages/sysManagement/user/index.vue"),
        meta: {
          title: "用户管理",
          isShow: true,
        },
      },
      {
        path: "/sysManagement/sysRoleManagement",
        name: "sysRoleManagement",
        component: () => import("../pages/sysManagement/role/index.vue"),
        meta: {
          title: "角色管理",
          isShow: true,
        },
      },
      {
        path: "/sysManagement/versionManagement",
        name: "versionManagement",
        component: () => import("../pages/sysManagement/version/index.vue"),
        meta: {
          title: "版本管理",
          isShow: true,
        },
      },
    ],
  },
];

export default pageRoutes;
