import { createStore } from 'vuex'
import menu from './menu'
import warehouse from './warehouse'

const store = createStore({
    modules: {
        menu,
        warehouse
    }
})

export default store