<template>
  <div class="title">资本数字空间运营平台</div>
  <div style="display: flex; align-items: center">
    <div style="margin-right: 5px">{{userName}}</div>
    <el-popover placement="bottom-start" :width="80" trigger="hover">
      <template #reference>
        <el-icon :size="16" color="#fff">
          <ArrowDown />
        </el-icon>
      </template>
      <div style="text-align: center; cursor: pointer" @click="logout">退出登录</div>
    </el-popover>
  </div>
</template>

<script>
import { ArrowDown } from "@element-plus/icons";
import { useRouter } from 'vue-router';
import { reactive, onMounted, toRefs } from 'vue'
export default {
  components: {
    ArrowDown,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      userName: ''
    })

    onMounted(() => {
      state.userName = localStorage.getItem('userName')
    })

    const logout = () => {
      localStorage.removeItem('user_token')
      router.push({ path: '/login' })
    }

    return {
      ...toRefs(state),
      logout
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 20px;
  font-weight: 500;
}
</style>
