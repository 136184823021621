import Layout from "../pages/layout/index.vue";

const childPageRoutes = [
  {
    path: "/warehouse",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/warehouse/add.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/warehouse/edit.vue"),
      },
    ],
  },
  {
    path: "/commandWarehouse",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/commandWarehouse/add.vue"),
      },
      {
        path: "edit",
        component: () => import("../pages/commandWarehouse/edit.vue"),
      },
    ],
  },
  {
    path: "/userManagement",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/userManagement/add.vue"),
      },
      {
        path: "detail",
        component: () => import("../pages/userManagement/detail.vue"),
      },
    ],
  },
  {
    path: "/roleManagement",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/roleManagement/add.vue"),
      },
      {
        path: "detail",
        component: () => import("../pages/roleManagement/detail.vue"),
      },
    ],
  },
  {
    path: "/content",
    component: Layout,
    children: [
      {
        path: "detail",
        component: () =>
          import(
            "../pages/contentManagement/interpretationStrongOperators/detail.vue"
          ),
      },
      {
        path: ":type",
        component: () =>
          import(
            "../pages/contentManagement/interpretationStrongOperators/addOrEdit.vue"
          ),
      },
    ],
  },
  {
    path: "/content/contentManagement",
    component: Layout,
    children: [
      {
        path: "detail",
        component: () =>
          import(
            "../pages/contentManagement/personalityRecommendationStrategy/detail.vue"
          ),
      },
      {
        path: "addOrEdit",
        component: () =>
          import(
            "../pages/contentManagement/personalityRecommendationStrategy/add.vue"
          ),
      },
    ],
  },
  {
    path: "/sysManagement/sysUserManagement",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/sysManagement/user/add.vue"),
      },
      {
        path: "detail",
        component: () => import("../pages/sysManagement/user/detail.vue"),
      },
    ],
  },
  {
    path: "/sysManagement/sysRoleManagement",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/sysManagement/role/add.vue"),
      },
      {
        path: "detail",
        component: () => import("../pages/sysManagement/role/detail.vue"),
      },
    ],
  },
  {
    path: "/projectManagement",
    component: Layout,
    children: [
      {
        path: "add",
        component: () => import("../pages/projectManagement/add.vue"),
      },
      {
        path: "detail",
        component: () => import("../pages/projectManagement/detail.vue"),
      },
    ],
  },
];

export default childPageRoutes;
