<template>
  <div>
    <el-menu background-color="#4A5259" unique-opened text-color="#fff" router :default-active="currentPath" class="el-menu-vertical-demo">
      <template v-for="item in menu" :key="item.path">
        <el-menu-item v-if="item.meta.sole && item.meta.isShow" :index="item.path">
          <template #title>
            <el-icon :size='20'>
              <component :is="$icon[item.meta.icon]"></component>
            </el-icon>
            <span>{{ item.meta.title }}</span>
          </template>
        </el-menu-item>

        <el-sub-menu v-else-if="item.meta.isShow" :index="item.path" >
          <template #title>
            <el-icon :size='20'>
              <component :is="$icon[item.meta.icon]"></component>
            </el-icon>
            <span>{{ item.meta.title }}</span>
          </template>

          <template v-for="(child, index) in item.children" :key="index">
            <el-menu-item v-if="child.meta.isShow&&!child['children']" :index="child.path">
              {{ child.meta.title }}
            </el-menu-item>
            <el-sub-menu v-if="child.meta.isShow&&child['children']" :index="child.path">
              <template #title>{{ child.meta.title }}</template>
              <template v-for="(list, i) in child.children" :key="i">
                <el-menu-item v-if="list.meta.isShow" :index="list.path">
                  {{ list.meta.title }}
                </el-menu-item>
              </template>
            </el-sub-menu>
          </template>
        </el-sub-menu>
      </template>
    </el-menu>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { reactive, ref } from "vue";

const store = useStore();
const route = useRoute();
const currentRoute = ref(route.meta.sole);

let currentPath = '';
if (currentRoute.value === false) {
  currentPath = ref(route.path);
} else if (currentRoute.value === undefined) {
  const routeList = route.path.split('/');
  routeList.pop();
  let path = ''
  for (let i = 1; i < routeList.length; i++) {
    path += `/${routeList[i]}`;
  }
  currentPath = ref(path);
} else {
  currentPath = ref(route.meta.path);
}
let routes = store.state.menu.routes;
routes.forEach((item) => {
  item.meta.isShow = false;
  if (item.meta.path === "/content" ||item.meta.path === "/sysManagement") {
    item.children.forEach(child => {
      child.meta.isShow = false;
      if (child.children) {
        child.children.forEach(r => {
          r.meta.isShow = false;
        });
      }
    });
  }
});


const menus = localStorage.getItem("menus");
const menuList = JSON.parse(menus);
menuList.forEach(item => {
  routes.forEach((r) => {
    if (r.redirect === item.resourceContent) {
      r.meta.isShow = true;
    }
    r.children.forEach(k => {
      if (k.path === item.resourceContent) {
        k.meta.isShow = true;
        r.meta.isShow = true;
      }
      if (k.children) {
        k.children.forEach(i => {
          if (i.path === item.resourceContent) {
            k.meta.isShow = true;
            r.meta.isShow = true;
            i.meta.isShow = true;
          }
        })
      }
    })

  })
})
console.log(888,routes);
const menu = reactive(routes);

</script>

<style lang="scss" scoped>
.el-menu {
  border-right: 0;
  .el-sub-menu .el-menu-item {
    min-width: 0px !important;
  }
}
</style>
