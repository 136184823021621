const warehouse = {
    state: {
        warehouseInfo: {}
    },
    getters: {
        GET_WAREHOUSEINFO(state) {
            return state.warehouseInfo
        }
    },
    mutations: {
        SET_WAREHOUSEINFO(state, payload) {
            state.warehouseInfo = payload
        }
    }
}

export default warehouse;