import { createRouter, createWebHistory } from 'vue-router';

import pageRoutes from './pageRoutes';
import childPageRoutes from './childPageRoutes';

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/login.vue')
    }, {
        path: '/:pathMatch(.*)*',
        redirect: '/homePage'
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes: [...routes,...pageRoutes, ...childPageRoutes]
})

export default router;
