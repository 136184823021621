<template>
  <div class="layout-container">
    <div class="head">
      <nav-bar></nav-bar>
    </div>
    <div class="container">
      <div class="middle">
        <router-view />
      </div>
    </div>
    <side-bar class="left"></side-bar>
  </div>
</template>

<script>
import NavBar from "./header.vue";
import SideBar from "./sidebar.vue";

export default {
  components: {
    NavBar,
    SideBar,
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  height: 100%;
}
.head {
  background: rgb(56, 162, 138);
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 20px;
  color: #fff;
}
.container,
.left {
  float: left;
}
.container {
  width: 100%;
  height: calc(100% - 65px);
  overflow-y: auto;
  .middle {
    margin: 0 0 0 200px;
    padding: 20px;
    background: rgb(237, 241, 242);
    min-height: 100%;
    box-sizing: border-box;
  }
}
.left {
  width: 200px;
  height: calc(100% - 65px);
  margin-left: -100%;
  background: #4a5259;
  overflow-y: auto;
}
</style>
